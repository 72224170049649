"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomPropListDateRangeFilterComponent = void 0;
var field_1 = require("@codebuild/glaze/libs/form/field");
var form_builder_1 = require("@codebuild/glaze/libs/form/form-builder");
var form_control_1 = require("@codebuild/glaze/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/glaze/libs/libs/abstract.component");
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var datepicker_form_control_type_1 = require("@codebuild/glaze/modules/codebuild/input-fields/form-control-types/datepicker.form-control-type");
var datepicker_1 = require("@codebuild/glaze/modules/codebuild/input-fields/react/datepicker/datepicker");
var moment = require("moment");
var React = require("react");
var form_1 = require("../../../components/form/form");
var CustomPropListDateRangeFilterComponent = (function (_super) {
    __extends(CustomPropListDateRangeFilterComponent, _super);
    function CustomPropListDateRangeFilterComponent() {
        var _this = this;
        var _a, _b;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                from: new field_1.Field({
                    placeholder: '',
                    value: _this.props.startDate || moment().add(-1, 'month').toDate(),
                    name: 'from',
                    label: '-tól',
                    validators: []
                }),
                to: new field_1.Field({
                    placeholder: '',
                    value: ((_b = (_a = _this.props.control$) === null || _a === void 0 ? void 0 : _a.getQuery()) === null || _b === void 0 ? void 0 : _b.to) || moment().toDate(),
                    name: 'to',
                    label: '-ig',
                    validators: []
                }),
            }
        });
        return _this;
    }
    CustomPropListDateRangeFilterComponent.prototype.componentDidMount = function () {
        var _this = this;
        this.form.fields.from.$value.subscribe(function (v) { return _this.handleChange(_this.props.from, v); });
        this.form.fields.to.$value.subscribe(function (v) { return _this.handleChange(_this.props.to, v); });
    };
    CustomPropListDateRangeFilterComponent.prototype.render = function () {
        return React.createElement(form_1.Form, { className: 'w-100 row' },
            React.createElement("div", { className: 'col-12' },
                React.createElement(form_control_1.FormControl, { field: this.form.fields.from, type: datepicker_form_control_type_1.DatepickerFormControlType },
                    React.createElement(datepicker_1.Datepicker, { className: 'datepicker-basic', disabled: false, type: 'date', locale: 'hu' }))),
            React.createElement("div", { className: 'col-12' },
                React.createElement(form_control_1.FormControl, { field: this.form.fields.to, type: datepicker_form_control_type_1.DatepickerFormControlType },
                    React.createElement(datepicker_1.Datepicker, { className: 'datepicker-basic', disabled: false, type: 'date', locale: 'hu' }))));
    };
    CustomPropListDateRangeFilterComponent.prototype.handleChange = function (property, value) {
        this.props.control$.set(property, value);
        this.props.control$.page(0);
    };
    return CustomPropListDateRangeFilterComponent;
}(abstract_component_1.AbstractComponent));
exports.CustomPropListDateRangeFilterComponent = CustomPropListDateRangeFilterComponent;
