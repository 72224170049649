"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserPermissionHandler = exports.isExcludedPermission = exports.isProductOwner = exports.hasRole = exports.getRole = exports.checkPermission = exports.userHasPermission = exports.calculatedAttributes = exports.Role = void 0;
var lodash_1 = require("lodash");
var get_user_1 = require("./authentication/get-user");
var Role;
(function (Role) {
    Role["admin"] = "admin";
    Role["superadmin"] = "superadmin";
    Role["b2bCustomer"] = "b2b-customer";
    Role["b2bSupplier"] = "b2b-supplier";
    Role["b2cCustomer"] = "b2c-customer";
    Role["finance"] = "finance";
    Role["delivery"] = "delivery";
    Role["commercialManager"] = "commercialManager";
    Role["marketingManager"] = "marketingManager";
    Role["procurement"] = "procurement";
    Role["procurementLeader"] = "procurement-leader";
    Role["sales"] = "sales";
    Role["shop"] = "shop";
    Role["storekeeper"] = "storekeeper";
    Role["telesales"] = "telesales";
})(Role = exports.Role || (exports.Role = {}));
exports.calculatedAttributes = ['cardboardPerPalette', 'bottlePerPalette', 'paletteHeight', 'cardboardGrossWeight', 'paletteWeightGrossWeight'];
var userHasPermission = function (permission) {
    var _a, _b, _c, _d;
    var user = (0, get_user_1.getUser)();
    var isSuperAdmin = (_b = (_a = user === null || user === void 0 ? void 0 : user.publicMeta) === null || _a === void 0 ? void 0 : _a.permissions.role.permissions) === null || _b === void 0 ? void 0 : _b.includes('superadmin');
    return ((_d = (_c = user === null || user === void 0 ? void 0 : user.publicMeta) === null || _c === void 0 ? void 0 : _c.permissions.role.permissions) === null || _d === void 0 ? void 0 : _d.includes(permission)) || isSuperAdmin;
};
exports.userHasPermission = userHasPermission;
var checkPermission = function (permissions) {
    var e_1, _a;
    if (!permissions.length) {
        return true;
    }
    try {
        for (var permissions_1 = __values(permissions), permissions_1_1 = permissions_1.next(); !permissions_1_1.done; permissions_1_1 = permissions_1.next()) {
            var p = permissions_1_1.value;
            if ((0, exports.userHasPermission)(p)) {
                return true;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (permissions_1_1 && !permissions_1_1.done && (_a = permissions_1.return)) _a.call(permissions_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return false;
};
exports.checkPermission = checkPermission;
var getRole = function () {
    var _a;
    var user = (0, get_user_1.getUser)();
    return (_a = user === null || user === void 0 ? void 0 : user.publicMeta) === null || _a === void 0 ? void 0 : _a.role;
};
exports.getRole = getRole;
var hasRole = function (role) { return (0, exports.getRole)() === role; };
exports.hasRole = hasRole;
var isProductOwner = function (product) {
    var _a, _b;
    var user = (0, get_user_1.getUser)();
    return ((_a = user === null || user === void 0 ? void 0 : user.publicMeta) === null || _a === void 0 ? void 0 : _a.ownership) === ((_b = product === null || product === void 0 ? void 0 : product.mozaikAttributes) === null || _b === void 0 ? void 0 : _b.manufacturer);
};
exports.isProductOwner = isProductOwner;
var isExcludedPermission = function (permissions) {
    var _a, _b, _c;
    if (!permissions.length) {
        return true;
    }
    var user = (0, get_user_1.getUser)();
    return !!(0, lodash_1.intersection)((_c = (_b = (_a = user === null || user === void 0 ? void 0 : user.publicMeta) === null || _a === void 0 ? void 0 : _a.permissions) === null || _b === void 0 ? void 0 : _b.role) === null || _c === void 0 ? void 0 : _c.permissions, permissions).length;
};
exports.isExcludedPermission = isExcludedPermission;
var extraAttributesKeys = [
    'bottleEAN',
    'cardboardEAN',
    'palletEAN',
    'vtsz',
    'jovedekiFajta',
    'bottleHeight',
    'bottleWidth',
    'bottleDepth',
    'bottleNetWeight',
    'bottleVolume',
    'bottleGrossWeight',
    'cardboardWidth',
    'cardboardHeight',
    'cardboardDepth',
    'bottlePerCardboard',
    'cardboardPerRow',
    'cardboardRowPerPalette',
    'packagingType',
    'packagingKind',
    'servingTemperature',
    'technology',
    'cardboardNetWeight',
    'cardboardGrossWeight',
    'bottlePerPalette',
    'cardboardPerPalette',
    'paletteWeightGrossWeight',
    'paletteHeight',
    'allergens',
    'ingredients'
];
var extraAttributesKeysCanEditProductOwner = [
    'bottleEAN',
    'cardboardEAN',
    'palletEAN',
    'vtsz',
    'jovedekiFajta',
    'bottleHeight',
    'bottleWidth',
    'bottleDepth',
    'bottleNetWeight',
    'bottleVolume',
    'bottleGrossWeight',
    'cardboardWidth',
    'cardboardHeight',
    'cardboardDepth',
    'bottlePerCardboard',
    'cardboardPerRow',
    'cardboardRowPerPalette',
    'packagingType',
    'packagingKind',
    'servingTemperature',
    'technology',
    'cardboardNetWeight',
    'allergens',
    'ingredients'
];
var attributesKeysCanSeeManufacturer = [
    'alcohol',
    'capacity',
    'bottle-type',
    'bio',
    'vegan',
    'organic',
    'koser',
    'gluten-free',
    'gmo-free',
    'containes-sulphites',
    'remaining-sugar',
    'acid',
    'protection-of-origin',
    'style',
    'style-1',
    'style-2',
    'acidity',
    'tannin',
    'sweetness',
    'color-of-bottle',
    'lock-type',
    'wine-growing-region',
    'sweetness-level',
    'Color',
    'color',
    'grape-variaty',
    'grape-variaty-1',
    'grape-variaty-2',
    'grape-variaty-3',
    'grape-variaty-4',
    'grape-variaty-5',
    'grape-variaty-6',
    'food-pairing',
    'food-pairing-1',
    'food-pairing-2',
    'smell-1',
    'smell-2',
    'smell-3',
    'taste-1',
    'taste-2',
    'taste-3',
    'wine-region',
    'wine-region-1',
    'wine-region-2',
    'manufacturing-technology',
    'technology',
];
var attributesKeysCanEditManufacturer = [
    'alcohol',
    'capacity',
    'bottle-type',
    'bio',
    'vegan',
    'organic',
    'koser',
    'gluten-free',
    'gmo-free',
    'containes-sulphites',
    'remaining-sugar',
    'acid',
    'protection-of-origin',
    'style',
    'style-1',
    'style-2',
    'acidity',
    'tannin',
    'sweetness',
    'color-of-bottle',
    'lock-type',
    'wine-growing-region',
    'sweetness-level',
    'Color',
    'color',
    'grape-variaty',
    'grape-variaty-1',
    'grape-variaty-2',
    'grape-variaty-3',
    'grape-variaty-4',
    'grape-variaty-5',
    'grape-variaty-6',
    'food-pairing',
    'food-pairing-1',
    'food-pairing-2',
    'smell-1',
    'smell-2',
    'smell-3',
    'taste-1',
    'taste-2',
    'taste-3',
    'wine-region',
    'wine-region-1',
    'wine-region-2',
    'manufacturing-technology',
    'technology',
];
var nonAttributes = [
    'sku',
    'manufacturer',
    'title',
    'subTitle',
    'name',
    'vintage'
];
var UserPermissionHandler = (function () {
    function UserPermissionHandler() {
    }
    UserPermissionHandler.canSeeProductExtraAttribute = function (key) {
        if ((0, exports.checkPermission)(['superadmin'])) {
            return true;
        }
        if ((0, exports.checkPermission)(['productOwner'])) {
            return extraAttributesKeys.includes(key);
        }
    };
    UserPermissionHandler.canEditProductExtraAttribute = function (key) {
        if (exports.calculatedAttributes.includes(key)) {
            return false;
        }
        if ((0, exports.checkPermission)(['superadmin'])) {
            return true;
        }
        if ((0, exports.checkPermission)(['productOwner'])) {
            return extraAttributesKeysCanEditProductOwner.includes(key);
        }
    };
    UserPermissionHandler.canSeeProductAttribute = function (attribute) {
        if ((0, exports.checkPermission)(['superadmin'])) {
            return true;
        }
        if ((0, exports.checkPermission)(['productOwner'])) {
            return attribute.productOwnerEnableEdit;
        }
    };
    UserPermissionHandler.canEditProductAttribute = function (attribute) {
        if ((0, exports.checkPermission)(['superadmin'])) {
            return true;
        }
        if ((0, exports.checkPermission)(['productOwner'])) {
            return attribute.productOwnerEnableEdit;
        }
    };
    return UserPermissionHandler;
}());
exports.UserPermissionHandler = UserPermissionHandler;
